/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var ms_ie = false;
var ua = window.navigator.userAgent;
var old_ie = ua.indexOf('MSIE ');
var new_ie = ua.indexOf('Trident/');

if ((old_ie > -1) || (new_ie > -1)) {
   ms_ie = true;
}

function resize_with_delay(callback) {
  var rtime;
  var timeout = false;
  var delta = 200;
  var resizeend = function () {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      if ('function' === typeof(callback)) {
        callback();
      }
    }
  };
  jQuery(window).resize(function () {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });
}

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    /**
     * Set equal height of elements
     *
     * @param {string} selector jQuery selector
     * @param {int} min_width Start width of screen when equal height is working
     * @returns {void}
     */
    equalHeights: function (selector, min_width, callback)
    {
      if (!$(selector).length) {
        return;
      }
      min_width = min_width || null;
      if (min_width && $(window).width() < min_width) {
        $(selector).css({'height': 'auto'});
        return;
      }
      $(selector).equalHeights();
      if ('function' === typeof (callback)) {
        callback();
      }

      $(window).resize(function () {
        $(selector).css({'height': 'auto'});
        if (min_width && $(window).width() < min_width) {
          return;
        }

        $(selector).equalHeights();
        if ('function' === typeof (callback)) {
          callback();
        }
      });
    },
    make_square_block: function (selector, callback, with_delay) {
      function square_block () {
        selector.each(function() {
          if ($(this).parent().hasClass('large') && $(this).parent().hasClass('featured') && (window.innerWidth > 767)) {
            $(this).height(($(this).width() / 2) - parseInt($(this).parent().css('padding-right'))); // Rectangle if large
          }
          else if ($(this).parent().hasClass('rectangle')) {
            $(this).height($(this).width() * 0.75);
          }
          else {
            $(this).height($(this).width()); // Square if not large
          }
        });
      }
      square_block();
      if ('undefined' !== typeof (with_delay) && true === with_delay) {
        resize_with_delay(function () {
          square_block();
          if ('function' === typeof (callback)) {
            callback();
          }
        });
      }
      else {
        $(window).resize(function () {
          square_block();
          if ('function' === typeof (callback)) {
            callback();
          }
        });
      }
    },
    load_more_properties: function (isotope_element, offset, type, count, location, featured_only, ids_except, statuses, rewards_only)
    {
      if ('undefined' === typeof(isotope_element)) {
        return false;
      }
      // For desktop load all cards if it's first page, or only 4 if next page; For devices always 2 cards
      var max_cards = window.innerWidth < 992 ? 2 : (offset ? 4 : count);
      if (type !== 'now-selling') {
        max_cards = window.innerWidth < 768 ? 2 : 3;
      }

      var is_scrolldown_needed = ('now-selling' === type);

      location = location || 'all';
      featured_only = featured_only || '';
      rewards_only = rewards_only || '';
      count = count || null;

      var properties_statuses;
      if (statuses) {
        properties_statuses = statuses;
      }
      else {
        properties_statuses = '';
      }

      var except = [];
      if (! ids_except) {
        if ($('section.other-properties-section').length) {
          except.push($('section.other-properties-section').data('except'));
        }
        else
        if ($('.section.properties .properties-wrapper').length &&
          ($('.section.properties .properties-bank').length) &&
          $('.section.properties .properties-bank').hasClass('not-enough-properties')) {
          $('.section.properties .properties-wrapper .properties, .section.properties .properties-bank .properties').each(function() {
            except.push($(this).data('property-id'));
          });
        }
      }
      else {
        except = ids_except;
      }
      if ($('.section.properties .properties-bank').length && ($('.section.properties .properties-bank').hasClass('initial') ||
        $('.section.properties .properties-bank').hasClass('not-enough-properties')) ||
        type === 'now-selling') {
        var data_type = type,
            data_featured_only = featured_only;
        if ($('.properties-bank').length && $('.properties-bank').hasClass('initial')) {
          data_type = '';
          data_featured_only = '';
        }
        var disable_linking = isotope_element.parents('.section.properties').find('.section-properties-content').hasClass('disable-linking');
        $.ajax({
          type: 'POST',
          url: site_url + "/wp-admin/admin-ajax.php",
          async: true,
          data: {
            action: 'get_cards_properties',
            offset: offset,
            type: data_type,
            location: location,
            count: count,
            except: except,
            disable_linking: disable_linking,
            featured_only: data_featured_only,
            statuses: properties_statuses,
            rewards_only: rewards_only
          },
          beforeSend: function () {
            $('#load-more-properties').addClass('busy');
            if ($('.section.properties .section-properties-content .properties-loader').length) {
              $('.section.properties .section-properties-content .properties-loader').show();
            }
          },
          error: function () {
            $('#load-more-properties').removeClass('busy');
            if ($('.section.properties .section-properties-content').length) {
              $('.section.properties .section-properties-content .properties-loader').hide();
            }
            if ($('.section.properties .properties-bank').length && $('.section.properties .properties-bank').hasClass('not-enough-properties')) {
              $('.section.properties .properties-bank').removeClass('not-enough-properties');
            }
          },
          success: function (data) {
            $('.section.properties .properties-loader').hide();
            if ('undefined' !== typeof (data) && 0 !== data && data.posts.length) {
              var first;
              var counter = 0;
              $(data.posts).each(function () {
                if (counter === 0) {
                  first = $(this);
                  if (first.hasClass('featured') && window.innerWidth > 767) {
                    max_cards--;
                  }
                  else if (window.innerWidth < 768) {
                    max_cards = 2;
                  }
                }

                if ($(this).hasClass('properties')) {
                  if (counter >= max_cards) {
                    if ($('.section.properties .properties-bank').length) {
                      $('.properties-bank').append($(this));
                    }
                  }
                  else {
                    isotope_element
                      .append($(this));
                    if (! isotope_element.hasClass('properties-bank')) {
                      isotope_element.isotope('appended', $(this));
                    }
                  }
                  counter++;
                }
                Sage.make_square_block($('article .article-bg'));
              });
              if ($('.message-if-empty').length) {
                if (!counter && !isotope_element.find('.properties').length) {
                  $('.message-if-empty').fadeIn();
                }
                else if(! isotope_element.hasClass('properties-bank')) {
                  $('.message-if-empty').fadeOut();
                }
              }
              if (! isotope_element.hasClass('properties-bank')) {
                isotope_element.isotope('layout');
              }
              if (!data.next) {
                $('#load-more-properties').hide();
                if (!$('.properties-bank .properties').length) {
                  $('#load-more-properties').hide();
                  if (isotope_element.parents('.section.properties')) {
                    if (isotope_element.parents('.section.properties').find('.properties-wrapper').length) {
                      isotope_element.parents('.section.properties').find('.message-if-empty').hide();
                    }
                  }
                }
              }
              else {
                $('#load-more-properties').removeClass('no-more-news').show();
              }
              if ('undefined' !== typeof (first) && is_scrolldown_needed) {
                $('html, body').animate({
                  scrollTop: first.offset().top - ($('#wpadminbar').length ? $('#wpadminbar').outerHeight() : 0) -
                    (first.height() / 2) - $('.banner').height()
                }, 800);
              }
            }
            $('#load-more-properties').removeClass('busy');
            // Only for section with properties search
            if ($('.section.properties').length) {
              var properties_filter = $('.section.properties .properties-wrapper');
              var num_cards = window.innerWidth < 768 ? 4 : ($('.section.properties .properties-wrapper .properties').length ? 3 : 6);
              if ($('.section.properties .filters').data('load-all')) {
                num_cards = 9999;
              }
              var prop_type_filter = '';
              // Location
              var location = '';
              if ($('.locations-filter .lower-level-locations a.active')) {
                var prefix = '';
                $('.locations-filter .lower-level-locations a.active').each(function () {
                  location += prefix + $(this).data('id');
                  prefix = ',';
                });
              }
              if (!location) { // no lower locations selected
                var upper_location_id = $('.upper-location a.active').data('id');
                $('.lower-location a').each(function () {
                  if ($(this).data('parent') === upper_location_id || 'all' === upper_location_id) {
                    location += prefix + $(this).data('id');
                    prefix = ',';
                  }
                });
              }
              if (!location) {
                location = '-1';
              }
              if ($(this).hasClass('status')) {
                $('.properties-filter a.all-filter').removeClass('active');
                  }
              if ($(this).hasClass('all-filter')) {
                $('.properties-filter a.status').removeClass('active');
              }
              if ($('.section.properties .section-properties-content').data('rewards_only') === 'rewards') {
                rewards_only = true;
              }
              var prop_prefix = '';
              $('.properties-filter .property_types .property_type').each(function () {
                if ( !( $(this).hasClass('featured-properties-filter') ) ) {
                  prop_type_filter += prop_prefix + $(this).data('type');
                  prop_prefix = ',';
                }
              });

              var prop_statuses = '';
              if ($('.properties-filter .status.active').length) {
                prop_prefix = '';
                $('.properties-filter .status.active').each(function () {
                  if (!($(this).hasClass('featured-properties-filter'))) {
                    prop_statuses += prop_prefix + $(this).data('status');
                    prop_prefix = ',';
                  }
                });
              }
              else if ($('.properties-filter a.all-filter').hasClass('active')) {
                prop_prefix = '';
                $('.properties-filter a.status').each(function () {
                  if (!($(this).hasClass('featured-properties-filter'))) {
                    prop_statuses += prop_prefix + $(this).data('status');
                    prop_prefix = ',';
                  }
                });
              }

              var featured_only = false;
              if ($('.properties-filter a.featured-properties-filter.active').length) {
                featured_only = true;
              }
              var prop_types;
              if ($('.section.properties .properties-bank').length && $('.section.properties .properties-bank').hasClass('initial')) {
                prop_types = '';
              }
              else {
                prop_types = prop_type_filter;
              }
              var properties_from_bank = Sage.get_properties_from_bank($('.section.properties .properties-bank'), num_cards, prop_type_filter, location, featured_only, '', $('.section.properties .properties-wrapper .properties').length, prop_statuses);
              var first_element;
              if (! properties_from_bank.length) {
                if (isotope_element.hasClass('properties-bank') && !$('.section.properties .properties-wrapper .properties').length) {
                  $('.section.properties #load-more-properties').hide();
                  $('.section.properties .message-if-empty').fadeIn();
                }
              }
              else {
                // show button for load more properties
                $('.section.properties #load-more-properties').hide();
                $('.section.properties .message-if-empty').hide();
                if ($('.section.properties .properties-loader').length) {
                  $('.section.properties .properties-loader').hide();
                }
              }

              properties_from_bank.forEach(function(current_property) {
                properties_filter
                  .append(current_property)
                  .isotope('appended', current_property);
                if ('undefined' === typeof(first_element)) {
                  first_element = current_property;
                }
              });

              var cards_with_featured = num_cards;
              var found_featured = false;
              properties_filter.find('.properties').each(function() {
                if ($(this).hasClass('large')) {
                  found_featured = true;
                }
              });
              if (found_featured) {
                cards_with_featured--;
              }

              if ($('.section.properties .properties-bank').hasClass('initial') &&
                properties_filter.find('.properties').length &&
                !$('.section.properties .properties-bank').hasClass('second-query') &&
                data.next && true !== $('.filters').data('load-all')
              ) {
                if (properties_filter.find('.properties').length < cards_with_featured) {
                  var except = [];
                  if ($('.section.properties .properties-wrapper article.properties').length) {
                    $('.section.properties .properties-wrapper article.properties').each(function () {
                      except.push($(this).data('property-id'));
                    });
                  }
                  Sage.load_more_properties(
                    isotope_element, // container for properties (bank)
                    0, // offset
                    prop_types, // properties types
                    cards_with_featured - properties_filter.find('.properties').length, // count
                    location, // properties locations
                    featured_only, // is featured only
                    except, // except these posts IDs
                    prop_statuses,
                    rewards_only
                  );
                  return false;
                }
                if ($('.section.properties .properties-bank').hasClass('second-query')) {
                  $('.section.properties .properties-bank').removeClass('second-query');
                }
              }

              Sage.make_square_block(properties_filter.find('.properties .article-bg'));
              properties_filter.isotope('reloadItems').isotope('layout');
              var ids_displayed = '';
              if ($('section.other-properties-section').length) {
                ids_displayed = $('section.other-properties-section').data('except');
              }
              else
              if ($('.section.properties .properties-wrapper').length &&
                ($('.section.properties .properties-bank').length) &&
                $('.section.properties .properties-bank').hasClass('not-enough-properties')) {
                $('.section.properties .properties-wrapper .properties, .section.properties .properties-bank .properties').each(function() {
                  if (ids_displayed) {
                    ids_displayed += ',';
                  }
                  ids_displayed += $(this).data('property-id');
                });
              }
              // show button for load more properties
              if (properties_from_bank.length &&
                (data.next || (
                  $('.section.properties .properties-bank .properties').length &&
                  $('.section.properties .properties-bank').hasClass('initial')) &&
                  properties_filter.find('.properties').length === cards_with_featured &&
                  Sage.get_properties_from_bank(
                    $('.section.properties .properties-bank'), // bank selector
                    num_cards, // number of properties to load
                    prop_type_filter, // properties types
                    location, // properties locations
                    featured_only, // only featured properties?
                    '', // except
                    $('.section.properties .properties-wrapper .properties').length,
                    statuses,
                    rewards_only
                  ).length // need large featured property?
                )
              ) {
                $('.section.properties #load-more-properties').show();
              }
              else {
                $('.section.properties #load-more-properties').hide();
              }
            }

            if ($('.section.properties .properties-bank').hasClass('initial')) {
              $('.section.properties .properties-bank').removeClass('initial');
            }

            Sage.make_square_block($('article .article-bg'));

            if (! isotope_element.find('.properties').length) {
              isotope_element.height('0');
            }
            if ($('.section.properties .properties-bank').length && $('.section.properties .properties-bank').hasClass('not-enough-properties')) {
              $('.section.properties .properties-bank').removeClass('not-enough-properties');
            }

            if (! isotope_element.hasClass('properties-bank')) {
              isotope_element.isotope('reloadItems').isotope('layout').isotope({ sortBy: ['location', 'name'], sortAscending: true });
            }
            else {
              setTimeout(function() {
                if ($(this).parents('.section.properties')) {
                  isotope_element.parents('.section.properties').find('.properties-wrapper').isotope('layout').isotope({ sortBy: ['location', 'name'], sortAscending: true });
                  setTimeout(function() {
                    if (! isotope_element.parents('.section.properties').find('.properties-wrapper > .properties').length) {
                      isotope_element.parents('.section.properties').find('.message-if-empty').fadeIn();
                    }
                  }, 100);
                }
              }, 180);
            }
          }
        });
      }
      else {
        $('.section.properties .properties-bank').each(function() {
          $(this).attr('style','');
        });
      }
    },
    get_properties_from_bank: function(bank_selector, limit, type, location, only_featured, except, large_not_needed, statuses)
    {
      var bank_properties = bank_selector.find('article.properties');
      var found_properties = [];
      var ignore_property;
      var have_large = false;
      var number_of_properties_to_load = limit;

      large_not_needed = true; // Force square box

      bank_properties.each(function() {
        $(this).attr('style','');
        $(this).removeClass('large');
      });
      bank_properties.each(function() {
        if ($(this).hasClass('featured')) {
          $(this).prependTo($(this).parent());
        }
      });
      bank_properties = bank_selector.find('article.properties');
      bank_properties.each(function () {
        ignore_property = false;
        if ($(this).hasClass('property-location-block')) {
          ignore_property = true;
        }
        if (type || location || only_featured || except) {
          var cardType = $(this).data('property-type').toString();
          if (cardType.indexOf(type) === -1 && type) {
            ignore_property = true;
          }
          if (location.split(',').indexOf($(this).data('location').toString()) < 0 && !ignore_property && location) {
            ignore_property = true;
          }
          if (only_featured && !($(this).hasClass('featured')) && !ignore_property) {
            ignore_property = true;
          }
          if (except && except.split(',').indexOf($(this).data('property-id').toString()) >= 0 && !ignore_property) {
            ignore_property = true;
          }
          if (statuses && statuses.split(',').indexOf($(this).data('status-id').toString()) < 0 && !ignore_property) {
            ignore_property = true;
          }
        }
        if (!ignore_property) {
          if ($(this).hasClass('featured') && !found_properties.length && !large_not_needed && !have_large) {
            $(this).addClass('large');
            number_of_properties_to_load--;
            have_large = true;
          }
          found_properties.push($(this));
        }
      });

      if (limit < found_properties.length) {
        found_properties.length = number_of_properties_to_load;
        found_properties.push('');
      }

      var found_locations = [];
      found_properties.forEach(function (element, index, array) {
        if ($.inArray(element.data('location'), found_locations) < 0) {
          found_locations.push(element.data('location'));
        }
      });

      bank_properties.each(function () {
        if (($(this).hasClass('property-location-block')) && ($.inArray($(this).data('location'), found_locations) >= 0)) {
          found_properties.push($(this));
        }
      });

      return found_properties;
    },
    remove_properties_from_bank: function () {
      // Remove from bank properties which are already loaded into isotope
      var ids_properties_in_isotope = [], location_blocks = [];
      $('.section.properties .properties-wrapper .properties').each(function() {
        if (typeof($(this).data('property-id')) !== 'undefined') {
          ids_properties_in_isotope.push($(this).data('property-id'));
        }
        else {
          if (location_blocks.indexOf($(this).data('location')) >= 0) {
            $(this).remove();
          }
          else {
            location_blocks.push($(this).data('location'));
          }
        }
      });
      $('.section.properties .properties-bank .properties').each(function() {
        if (typeof($(this).data('property-id')) !== 'undefined') {
          if (ids_properties_in_isotope.indexOf($(this).data('property-id')) >= 0) {
            $(this).remove();
          }
        }
        else {
          if (location_blocks.indexOf($(this).data('location')) >= 0) {
            $(this).remove();
          }
        }
      });
    },
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.banner nav').on('show.bs.collapse hide.bs.collapse', function () {
          $('button.hamburger').toggleClass('is-active');
        });
        if (0 !== $(window).scrollTop()) {
          $('header.banner').addClass('compressed');
        }

        $('.dropdown.yamm-fw .dropdown-toggler').on('mouseenter',  function () {
          if (!$(this).closest('.dropdown.yamm-fw').hasClass('open')) {
            var current_dropdown = $(this).closest('.dropdown.yamm-fw').attr('class');
            $(this).closest('#menu-header-menu').find('.dropdown-menu').each(function () {
              if ($(this).closest('.dropdown.yamm-fw').attr('class') !== current_dropdown) {
                $(this).hide();
                $(this).closest('.dropdown.yamm-fw').removeClass('open');
              }
            });
            $(this).siblings('.dropdown-menu').slideDown('500');
            $(this).closest('.dropdown.yamm-fw').addClass('open');
            $(this).closest('.banner').addClass('open');
          }
        });
        $('header.banner').on('mouseleave', function () {
          $(this).removeClass('.open');
          if ($(this).find('.dropdown.yamm-fw.open').length) {
            $(this).find('.dropdown.yamm-fw.open').each(function () {
              $(this).removeClass('open');
              $(this).find('.dropdown-menu').slideUp('500');
            });
          }
        });
        $('header.banner .dropdown').on('hide.bs.dropdown', function(e) {
          $(this).find('.dropdown-menu').slideUp('500');
        });

        $(window).on('scroll', function() {
          if (0 !== $(this).scrollTop()) {
            if (!$('header.banner').hasClass('compressed')) {
              $('header.banner').addClass('compressed');
            }
          }
          else if($('header.banner').hasClass('compressed')) {
            $('header.banner').removeClass('compressed');
          }
        });
        // Animate sections
        new WOW({
          mobile: false
        }).init();

        $('a[href*="#section"]').on('click', function(e) {
          var target = $(this).attr('href').split('#')[1];
          if (location.pathname.replace(/^\//, '').replace('/', '') === this.pathname.replace(/^\//, '').replace('/', '') &&
            location.hostname === this.hostname && "undefined" !== typeof(target) && $('#' + target).length
          ) {
            var target_offset = $('#'+target).offset();
            var target_top = target_offset.top - $('header.banner').height();
            $('html, body').animate({scrollTop: target_top}, 'slow');
            return false;
          }
        });

        if ($('#scroll-to-top').length) {
          $('#scroll-to-top').click(function () {
            $('html, body').animate({scrollTop: 0}, 150);
          });
        }

        if ($('.with-slider .swiper-container .video-cover.youtube-video').length) {
          var ids = [];
          window.yt_players = {};
          $('.with-slider .swiper-container .video-cover.youtube-video').each(function () {
            var id = $(this).siblings('.video-container').attr('id');
            ids.push(id);
            $(this).on('click', function (e) {
              if ($(this).css('opacity') === '0') {
                $(this).css('opacity', '1');
                if ('function' === typeof(window.yt_players[id].stopVideo)) {
                  window.yt_players[id].pauseVideo();
                }
              }
              else if (jQuery(e.target).hasClass('play-icon-fa')) {
                $(this).css('opacity', '0');
                if ('function' === typeof(window.yt_players[id].playVideo)) {
                  window.yt_players[id].playVideo();
                }
              }
            });
          });
          window.onYouTubeIframeAPIReady = function () {
            for (var i = 0; i < ids.length; i++) {
              window.yt_players[ids[i]] = new YT.Player(ids[i], {
                height: '100%',
                width: '100%',
                videoId: $('#' + ids[i]).data('ytid')
              });
            }
          };
          $.getScript('https://www.youtube.com/iframe_api');
        }

        new Swiper('.with-slider .swiper-container', {
          loop: true,
          slidesPerView: 1,
          nextButton: '.swiper-btn-next',
          prevButton: '.swiper-btn-prev',
          shortSwipes: false,
          longSwipesRatio: 0.05,
          longSwipesMs: 30,
          speed: 500,
          autoplay: {
            delay: 4500
          },
          onSlideChangeStart: function(swiper) {
            $('.with-slider .swiper-container .video-cover.youtube-video').each(function () {
              var id = $(this).siblings('.video-container').attr('id');
              $(this).css('opacity', '1');
              if (("undefined" !== typeof(window.yt_players[id])) && 'function' === typeof(window.yt_players[id].stopVideo)) {
                window.yt_players[id].pauseVideo();
              }
            });
            $('.section.splash .swiper-slide.with-video').each(function () {
              var video_cover = $(this).find('.video-cover:not(.youtube-video)');
              if (video_cover.length) {
                var video = video_cover.siblings('.video-container').find('video').get(0);
                if (!video.paused) {
                  video.pause();
                  video_cover.css('opacity', '1').css('background-image', '');
                  video_cover.find('a').each(function() {
                    $(this).css('display', 'inline-block');
                  });
                }
              }
            });
          }
        });
        /* Play video */
        $('.with-slider .swiper-container .video-cover:not(.youtube-video)').each(function () {
          $(this).click(function(e) {
            var video = $(this).siblings('.video-container').find('video').get(0);
            if (video.paused && e.target.className === 'play-icon-fa') {
              $(this).siblings('.video-container').removeClass('has-image');
              video.play();
              $(this).css('opacity', '0').css('background-image', 'none');
              $(this).find('a').each(function() {
                $(this).css('display', 'none');
              });
            }
            else {
              if (! $(this).siblings('.video-container').hasClass('has-image')) {
                e.preventDefault();
                video.pause();
                $(this).css('opacity', '1').css('background-image', '');
                $(this).find('a').each(function() {
                  $(this).css('display', 'inline-block');
                });
              }
            }
          });
        });
        // Don't play video if clicked on link
        $('.with-slider .swiper-container .cta-btn').click(function(e) {
          e.stopPropagation();
        });
        /* iPhone/iOs - Show overlay if video is paused */
        var video_tag = $('.with-slider .swiper-container .video-container video');
        video_tag.on('pause', function() {
          $('.with-slider .swiper-container .video-cover').css('opacity', '1');
        });
        /* Button with scroll to next section */
        if ($('.next-section-btn').length) {
          $('.next-section-btn').click(function (e) {
            var target_section = $(this).parents('.section').next();
            if (target_section) {
              var target_offset = target_section.offset();
              var target_top = target_offset.top - $('header.banner').height();
              $('html, body').animate({scrollTop: target_top}, 'slow');
              return false;
            }
          });
        }
        /* Header Navigation */
        $('.banner nav').on('show.bs.collapse hide.bs.collapse', function () {
          $('button.c-hamburger').toggleClass('is-active');
        });
        $('.banner nav').on('show.bs.collapse hidden.bs.collapse', function () {
          $('.banner .brand').toggleClass('white');
        });
        $('#menu-primary-navigation .dropdown').on('show.bs.dropdown', function (e) {
          $(this).find('.dropdown-menu').first().slideDown();
        });
        $('#menu-primary-navigation .dropdown').on('hidden.bs.dropdown', function (e) {
          $(this).find('.dropdown-menu').first().slideUp();
        });
        $('.banner .dropdown-toggler').on('click', function (e) {
          if ($(window).width() > Sage.grid_breakpoint) {
            e.preventDefault();
            location.href = $(this).attr('href');
          }
        });

        $('header.banner .dropdown').on('show.bs.dropdown', function(e){
          $(this).find('.dropdown-menu').first().stop(true, true).fadeIn();
          $('header.banner').addClass('open');
          $('.menu-new-homes .property-thumb:first, .menu-about .property-thumb:first').show();
        });
        $('header.banner .dropdown.menu-new-homes').on('shown.bs.dropdown', function(e){
          $('.menu-new-homes .yamm-content .megamenu > .dropdown').height('auto');
          Sage.equalHeights($('.menu-new-homes .yamm-content .megamenu > .dropdown'), 991);
        });
        $('.dropdown').on('hide.bs.dropdown', function(e){
          $('.property-thumb').hide();
          $(this).find('.dropdown-menu').first().stop(true, true).fadeOut();
          $('header.banner').removeClass('open');
        });
        $('.menu-new-homes .has-thumb, .menu-about .has-thumb').mouseenter(function (){
          var property_id = $(this).find('a').data('property-id');
          if (property_id && $('.property-'+property_id).length && !$('.property-'+property_id+':visible').length) {
            $('.property-thumb').fadeOut();
            $('.property-'+property_id).stop(true, true).fadeIn();
          }
        });

        // Registration button
        if ($('#registration_slide_down').length) {
          $('#registration_slide_down').click(function(e) {
            // Make registration lasso section to slide down
            e.preventDefault();
            $('.section.registration-lasso').css('background-image', 'none');
            $('.section.registration-lasso').slideDown();
            // Scroll down to the registration lasso block
            $('html body').animate({
              scrollTop: $('.section.registration-lasso').offset().top
            }, 1000);
          });
        }
        // Register validation
        function validate_registration_fields() {
          if ( !($('#is_consent').is(":checked")) ) {
            return false;
          }
          var is_valid = true;
          $('.section.registration-lasso .required, .section.registration-lasso [data-validation="required"]').each(function() {
            if ( (!$(this).val()) && ('checkbox' !== $(this).attr('type')) ) {
              is_valid = false;
            }
            if ('Emails[Primary]' === $(this).attr('name')) {
              var email_validation = /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/;
              if ( !(email_validation.test($(this).val())) ) {
                is_valid = false;
              }
            }
          });
          return is_valid;
        }

        if ($('.section.registration-lasso').length) {
          $('.section.registration-lasso').find('input, select, textarea').change(function() {
              $('.consent').prop('disabled', !(validate_registration_fields()));
          });
        }

        // Initialize swiper slider
        if ($('.gallery .slider-wrapper').length) {
          new Swiper ('.gallery .swiper-container', {
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev'
          });
        }

        if ($('.location__featured__carousel').length) {
          new Swiper ('.location__featured__carousel .swiper-container', {
            loop: true,
            slidesPerView: 4,
            spaceBetween: 10,
            nextButton: '.swiper-button-next-featured',
            prevButton: '.swiper-button-prev-featured',
            speed: 500,
            breakpoints: {
              1200: {
                slidesPerView: 3
              },
              1024: {
                slidesPerView: 2
              },
              768: {
                slidesPerView: 2
              },
              620: {
                slidesPerView: 1
              }
            }
          });
        }

        // Init height of the module blocks
        if ($('article .article-bg').length) {
          Sage.make_square_block($('article .article-bg'));
        }

        // Hide Lasso section if there's resistration slide-down button
        if ($('#registration_slide_down').length) {
          $('.section.registration-lasso').hide();
        }

        // To make circles for statistics section block needs width = height
        if ($('.section.statistics').length) {
          Sage.make_square_block($('.statistics .wrapper'));
          Sage.equalHeights($('.statistics .wrapper .description'), 0);
        }

        // On small screens add animation effect on click for side menu
        if ($('.side-menu').length) {
          if (!$('.side-menu > .widget_hier_page > ul > li > .children, .side-menu > .years > ul > li > .children').hasClass('collapse')) {
            $('.side-menu > ul > li > .children').addClass('collapse');
          }

          $('.side-menu > .widget_hier_page > ul > li > a, .side-menu > .years > ul > li > a').click(function (e) {
            if ($(window).width() < 992) {
              e.preventDefault();
              e.stopPropagation();
              $(this).siblings('.children').collapse('toggle');
            }
          });
          $('.side-menu > .current-page-title').click(function (e) {
            if ($(window).width() < 992) {
              $(this).siblings('.widget_hier_page').find('>ul>li>a').siblings('.children').collapse('toggle');
            }
          });

          $('.side-menu > .years > ul > li > ul > li:first-child').addClass('current_page_item');
          $('.side-menu > .years > ul > li > ul > li > a').click(function(e) {
            if ($($(this).attr('href')).length) {
              e.preventDefault();
              e.stopPropagation();
              $(this).parent().addClass('current_page_item');
              $(this).parent().siblings().removeClass('current_page_item');
              var target = $(this).attr('href');
              if (typeof target !== "undefined" && $(target).length) {
                var wpadminbar_height = 0;
                if ($('#wpadminbar').length) {
                  wpadminbar_height = $('#wpadminbar').height();
                }
                $('html, body').animate({scrollTop: $(target).offset().top - $('header.banner').height() - wpadminbar_height - $('.years .children').height()}, 'slow');
              }
              $(this).parents('.children').collapse('toggle');
            }
          });
        }

        if ($('.splash-image .delay-animate').length) {
          setTimeout(function () {
            $('.splash-image .content.delay-animate').addClass('slideInDown animated');
            $('.splash-image .content.delay-animate').css('visibility', 'visible');
          }, 1000);
        }

        // Set Google Map Width
        if ($('#wpgmza_map, .page-with-sidebar-menu .page-content .content .map').length) {
          $(window).resize(function(){
            var set_width;
            if (window.innerWidth > 992) {
              set_width = $('.container').outerWidth(true) - $('.container').css('margin-left').replace(/[^-\d\.]/g, '') - $('.sidebar-menu-wrapper').outerWidth();
              $('#wpgmza_map, .page-with-sidebar-menu .page-content .content .map').css('margin-left', '-'+($('#wpgmza_map, .page-with-sidebar-menu .page-content .content .map').parents('.page-content').css('padding-left')));
            }
            else {
              set_width = $('.section.content.page-with-sidebar-menu .container').outerWidth(true);
              $('#wpgmza_map, .page-with-sidebar-menu .page-content .content .map').css('margin-left', '-'+(parseInt($('#wpgmza_map, .page-with-sidebar-menu .page-content .content .map').parents('.page-content').css('padding-left').replace(/[^-\d\.]/g, '')) + parseInt($('#wpgmza_map, .page-with-sidebar-menu .page-content .content .map').parent().css('margin-left').replace(/[^-\d\.]/g, '')))+'px');
            }
            $('#wpgmza_map, .page-with-sidebar-menu .page-content .content .map').width(set_width);
          }).resize();
        }

        // Fix for displaying content with validation errors on page with sidebar after submitting Gravity Forms
        if ($('.page-with-sidebar-menu').length) {
          if ($('.gform_wrapper').length) {
            $('.gform_wrapper form').submit(function(e) {
              e.preventDefault(); // don't submit multiple times
              this.submit(); // use the native submit method of the form element
              $('.page-content').css('height', 'auto');
            });
          }

          if ($('.section.page-with-sidebar-menu .sidebar-menu-wrapper .current-page-title').length) {
            $('.section.page-with-sidebar-menu .sidebar-menu-wrapper .current-page-title').click(function () {
              $(this).siblings('.widget_hier_page').find('.current_page_parent > a').click();
            });
          }
        }

        if ($('.section.select_content').length) {
          var switched_value;
          $('.section.select_content').each(function () {
            $(this).find('.content-switcher').on('change', function () {
              switched_value = $(this).val();
              $(this).siblings('.switched-content').each(function() {
                if ($(this).data('value').toString() === switched_value.toString()) {
                  $(this).show();
                }
                else {
                  $(this).hide();
                }
              });
            });
          });
        }

        if ($('.section.faqs').length) {
          $('.section.faqs .faq-question').click(function () {
           $(this).siblings('.answer').slideToggle();
           $(this).toggleClass('active');
          });
        }

        // Initialize swiper slider for properties lower locations
        if ($('.section.properties .lower-level-locations').length) {
          window.lower_locations_swiper = new Swiper ('.section.properties .lower-level-locations .swiper-container', {
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            slidesPerView: 'auto',
            observer: true,
            observeParents: true
          });
        }

        // See more properties feature
        if ($('.section.other-properties-section').length) {
          // for mobiles show only 2 cards, rest of cards put into properties-bank container for loading later
          if ($(window).width() < 992) {
            $('.properties-bank').append($('.section.other-properties-section .other-properties-selling .properties:nth-child(n + 3)'));
          }
          // Isotope init
          var properties = $('.section.other-properties-section .other-properties-selling').isotope({
            layoutMode: 'masonry',
            itemSelector: '.properties',
            animationEngine : "jquery"
          });
          // Load more news
          $('#load-more-properties').on('click', function (e) {
            e.preventDefault();
            if ($('#load-more-properties').hasClass('busy')) {
              return false;
            }
            // for mobiles show 2 cards per time, for desktops - 4 per time
            var max_cards = $(window).width() < 992 ? 2 : 4;
            // if there are properties in bank then load them
            if ($('.properties-bank .properties').length) {
              var first;
              $('.properties-bank .properties').each(function(i, e) {
                if ('undefined' === typeof(first)) {
                  first = $(this);
                }
                properties
                  .append($(this))
                  .isotope('appended', $(this));
                if ((i + 1) === max_cards) {
                  return false;
                }
              });
              Sage.make_square_block($('.section.other-properties-section .other-properties-selling article .article-bg'), function() {
                properties.isotope('layout');
              });
              $('html, body').animate({
                scrollTop: first.offset().top - ($('#wpadminbar').length ? $('#wpadminbar').height() : 0) -
                  (first.height() / 2) - $('.banner').height()
              }, 800);
              if (!$('.properties-bank .properties').length && $('#load-more-properties').hasClass('no-more-news')) {
                $('#load-more-properties').hide();
              }
              return false;
            }
            // load next page with properties cards
            Sage.load_more_properties(properties, $('.section.other-properties-section .properties').length, 'now-selling', null, $(this).data('location'));
          });
        }

        // Properties Search By Filters Block
        if ($('.section.properties').length) {

          if (window.innerWidth < 768 && !$('.section.properties .filters').data('load-all')) {
            var number_of_elements_into_bank = $('.section.properties .properties-wrapper article.properties').length - 4;
            $('.section.properties .properties-wrapper article.properties:nth-last-child(-n+' + number_of_elements_into_bank  + ')').each(function() {
              $('.section.properties .properties-bank').append($(this));
            });
          }
        }
        // Isotope init
        if ($('.section.properties').length) {
          $(window).on('load', function () {
            var load_all_properties = $('.section.properties .filters').data('load-all');
            $('.section.properties .properties-wrapper').css('opacity', 1).fadeIn();
            var properties_filter = $('.section.properties .properties-wrapper').isotope({
              layoutMode: 'masonry',
              itemSelector: '.properties-wrapper .properties',
              percentPosition: true,
              sortBy: ['location', 'name'],
              getSortData: {
                location: '.location',
                name: '.property-name'
              },
              masonry: {
                columnWidth: '.section.properties .section-properties-content .grid_sizer',
                gutter: 0
              }
            });
            // Isotope filter
            var is_upper_location;
            $('body').on('change', '.js-card-filter', function(){
              var $self = $(this),
                  val = $self.find(":selected").val();

              $('.upper-location a[data-id="'+val+'"]').click();
            });

            $('body').on('change', '.js-card-filter-2', function(){
              var $self = $(this),
                  val = $self.find(":selected").val();

              $('.lower-locations-container a').removeClass('active');
              $('.lower-locations-container a[data-id="'+val+'"]').click();
            });
            $('.section.properties .filters a').on('click', function (e) {
              e.preventDefault();
              is_upper_location = false;

              if ($(this).hasClass('status')) {
                var current_status = $(this).data('status');
                $('.properties-filter a.all-filter').removeClass('active');
                $('.properties-filter a.status').each(function () {
                  if ($(this).data('status') !== current_status) {
                    $(this).removeClass('active');
                  }
                });
              }
              if ($(this).hasClass('all-filter')) {
                if ($(this).hasClass('active')) {
                  return false;
                }
                $('.properties-filter a.status').removeClass('active');
              }

              if ($('#load-more-properties').hasClass('filters-disabled')) {
                return false;
              }
              // Upper locations filter if already active do nothing
              if ($(this).hasClass('active') && $(this).parent().hasClass('upper-location')) {
                return false;
              }
              if ($('#load-more-properties').hasClass('busy') || $('.section.properties .section-properties-content .properties-loader:visible').length) {
                return false;
              }
              if ($('.section.properties .section-properties-content .properties-loader').length) {
                $('.section.properties .section-properties-content .properties-loader').show();
              }
              // If already active button
              if ($(this).hasClass('active')) {
                // Types filter
                if ($(this).parent().hasClass('properties-filter')) {
                  if ($(this).hasClass('active') && $(this).hasClass('status')) {
                    $(this).removeClass('active');
                    $('.properties-filter a.all-filter').addClass('active');
                  }
                }
                if ($(this).parent().hasClass('lower-location')) {
                  $(this).removeClass('active');
                }
              }
              else {
                $(this).addClass('active');
                // Higher level location
                if ($(this).parent().hasClass('upper-location')) {
                  is_upper_location = true;
                  var id_upper_location = $(this).data('id');
                  // Remove selection from siblings
                  $(this).parent('.upper-location').siblings().find('a').removeClass('active');
                  // Reset selecton from lower locations
                  $(this).parents('.locations-filter').find('.lower-location a').removeClass('active');
                  // Show corresponding lower locations
                  $(this).parents('.locations-filter').find('.lower-location a').each(function () {
                    if (id_upper_location  === $(this).data('parent')) {
                      $(this).parent().show();
                    }
                    else {
                      $(this).parent().hide();
                    }
                  });
                  $('.js-card-filter-2 option').each(function () {
                    if (id_upper_location  === $(this).data('parent')) {
                      $(this).show();
                    }
                    else {
                      $(this).not("[value='*']").hide();
                    }
                  });
                  // Clean properties bank
                  $('.section.properties .properties-bank').html('');
                  $('.section.properties .properties-bank').addClass('initial');
                  $('#load-more-properties').hide();
                  setTimeout(function() {
                    window.lower_locations_swiper.slideTo(0, 0);
                  }, 1);
                }
              }
              $('#load-more-properties').removeClass('no-more-properties');

              properties_filter.isotope({ sortBy: ['name'], sortAscending: true });
              var elements_in_isotope = properties_filter.isotope('getItemElements');
              if ($('.section.properties .properties-bank').hasClass('initial')) {
                properties_filter.isotope('remove', elements_in_isotope);
              }
              if (! $('.section.properties .properties-bank').hasClass('initial')) {
                elements_in_isotope.forEach(function (current_element){
                  $('.section.properties .properties-bank').append(current_element);
                });
                $('.section.properties .properties-bank .properties').each(function() {
                  if ($(this).hasClass('large')) {
                    $(this).removeClass('large');
                  }
                });
              }
              else {
                $('.section.properties .properties-bank').html('');
              }
              $('#load-more-properties').addClass('filters-disabled');

              Sage.make_square_block($('.section.properties .properties-wrapper article.properties .article-bg'));
              // Location
              var location = '';
              if ($('.locations-filter .lower-level-locations a.active')) {
                var prefix = '';
                $('.locations-filter .lower-level-locations a.active').each(function () {
                  location += prefix + $(this).data('id');
                  prefix = ',';
                });
              }
              if (!location) { // no lower locations selected
                var upper_location_id = $('.upper-location a.active').data('id');
                $('.lower-location a').each(function () {
                  if ($(this).data('parent') === upper_location_id || 'all' === upper_location_id) {
                    location += prefix + $(this).data('id');
                    prefix = ',';
                  }
                });
              }
              if (!location) {
                location = '-1';
              }

              var prop_type = '';
              var prop_prefix = '';
              $('.properties-filter .property_types .property_type').each(function () {
                if ( !( $(this).hasClass('featured-properties-filter') ) ) {
                  prop_type += prop_prefix + $(this).data('type');
                  prop_prefix = ',';
                }
              });

              var statuses = '';
              if ($('.properties-filter .status.active').length) {
                prop_prefix = '';
                  $('.properties-filter .status.active').each(function () {
                  if ( !( $(this).hasClass('featured-properties-filter') ) ) {
                    statuses += prop_prefix + $(this).data('status');
                    prop_prefix = ',';
                  }
                });
              }
              else if ($('.properties-filter a.all-filter').hasClass('active')) {
                prop_prefix = '';
                $('.properties-filter a.status').each(function () {
                  if ( !( $(this).hasClass('featured-properties-filter') ) ) {
                    statuses += prop_prefix + $(this).data('status');
                    prop_prefix = ',';
                  }
                });
              }

              var featured_only = false;
              if ($('.properties-filter a.featured-properties-filter.active').length) {
                featured_only = true;
              }

              var rewards_only = false;
              if ($('.section.properties .section-properties-content').data('rewards_only') === 'rewards') {
                rewards_only = true;
              }

              var num_cards = 0;
              if (load_all_properties) {
                num_cards = 9999;
              }
              else {
                num_cards = window.innerWidth < 768 ? 4 : ($('.section.properties .properties-wrapper .properties').length ? 3 : 6);
              }
              Sage.load_more_properties(
                $('.section.properties .properties-bank'), // element selector
                0, // offset
                prop_type, // properties types
                $('.section.properties .properties-bank').data('initial-min-bank-size'), // count
                location, // properties locations
                featured_only, // is featured only
                null,
                statuses,
                rewards_only
              );
              var properties_from_bank = Sage.get_properties_from_bank(
                $('.section.properties .properties-bank'), // bank selector
                num_cards, // limit
                prop_type, // properties types
                location, // properties locations
                featured_only, // is featured only
                '', // except
                $('.section.properties .properties-wrapper .properties').length, // large not needed if length > 0
                statuses
              );
              if (properties_from_bank.length < num_cards && !$('.section.properties .properties-bank').hasClass('initial')) {
                $('.section.properties .properties-bank').addClass('not-enough-properties');
                Sage.load_more_properties(
                  $('.section.properties .properties-bank'), // element selector
                  0, // offset
                  prop_type, // properties types
                  num_cards - properties_from_bank.length, // count
                  location, // properties locations
                  featured_only, // is featured only
                  null,
                  statuses,
                  rewards_only
                );
                properties_filter.isotope('reloadItems').isotope('layout');
              }
              else {
                if (! $('.properties-bank').hasClass('initial')) {
                  $('#load-more-properties').show();
                }
              }

              var first;
              properties_from_bank.forEach(function(current_property) {
                if (!properties_filter.find('article.properties[data-property-id="' + $(current_property).data('property-id') + '"]').length) {
                  if (typeof($(current_property).data('property-id')) === "undefined") {
                    window.testvar = current_property;
                  }
                  properties_filter
                    .append(current_property)
                    .isotope('appended', current_property);
                  if ('undefined' === typeof(first)) {
                    first = current_property;
                  }
                }
              });
              Sage.remove_properties_from_bank();
              Sage.make_square_block(properties_filter.find('.properties .article-bg'));
              if (! $('.section.properties .properties-bank').hasClass('initial')) {
                if (! $('.properties-wrapper .properties').length) {
                  $('.section.properties #load-more-properties').hide();
                  $('.section.properties .message-if-empty').fadeIn();
                }
                else {
                  $('.section.properties .message-if-empty').hide();
                }
              }
              if (is_upper_location) {
                properties_filter.isotope('reloadItems').isotope('layout').isotope({ sortBy: ['location', 'name'], sortAscending: true });
              }
              else {
                properties_filter.isotope();
                is_upper_location = false;
              }
              $('.section.properties .properties-bank .properties').each(function() {
                $(this).attr('style','');
              });
              if ($('.section.properties .properties-loader').length && !$('.properties-bank').hasClass('initial')) {
                $('.section.properties .properties-loader').hide();
              }
              $('#load-more-properties').removeClass('busy');
              $('#load-more-properties').removeClass('filters-disabled');
            });

            $('.section-properties-content.read-only .properties a').on('click', function(e) {
              e.preventDefault();
            });

            // Remove from bank properties which are already loaded into isotope
            var ids_properties_in_isotope = [];
            $('.section.properties .properties-wrapper .properties').each(function() {
              ids_properties_in_isotope.push($(this).data('property-id'));
            });
            $('.section.properties .properties-bank .properties').each(function() {
              if (ids_properties_in_isotope.indexOf($(this).data('property-id')) >= 0) {
                $(this).remove();
              }
            });

            var propertyFilter = getParameterByName('filter');
            if(propertyFilter) {
              $('.properties-filter a[data-status="'+propertyFilter+'"]').click();
            }
          });
        }

        // Load more properties
        if ($('#load-more-properties').length) {
          $('#load-more-properties').on('click', function (e) {
            e.preventDefault();
            if ($('#load-more-properties').hasClass('busy')) {
              return false;
            }
            // for show 3 cards per time
            var max_cards;
            if (load_all_properties) {
              max_cards = 9999;
            }
            else {
              max_cards = window.innerWidth < 768 ? 2 : ($('.section.properties .properties').length ? 3 : 6);
            }
            var properties_from_bank;
            var location = ''; var prefix = '';
            if ($('.lower-location a.active').length) {
              $('.lower-location a.active').each(function () {
                location += prefix + ($(this).data('id'));
                prefix = ',';
              });
            }
            else {
              // Find child locations for selected upper location
              var upper_location_id = $('.upper-location a.active').data('id');
              $('.lower-location a').each(function () {
                if (upper_location_id === $(this).data('parent')) {
                  location += prefix + ($(this).data('id'));
                  prefix = ',';
                }
              });
            }
            if (!location) {
              location = '-1';
            }
            var prop_type = '';
            var prop_prefix;
            prop_prefix = '';
            $('.properties-filter .property_types .property_type').each(function () {
              if ( !( $(this).hasClass('featured-properties-filter') ) ) {
                prop_type += prop_prefix + $(this).data('type');
                prop_prefix = ',';
              }
            });
            if ($(this).hasClass('status')) {
              $('.properties-filter a.all-filter').removeClass('active');
            }
            if ($(this).hasClass('all-filter')) {
              $('.properties-filter a.status').removeClass('active');
            }
            var statuses = '';
            if ($('.properties-filter .status.active').length) {
              prop_prefix = '';
              $('.properties-filter .status.active').each(function () {
                if (!($(this).hasClass('featured-properties-filter'))) {
                  statuses += prop_prefix + $(this).data('status');
                  prop_prefix = ',';
                }
              });
            }
            else if ($('.properties-filter a.all-filter').hasClass('active')) {
              prop_prefix = '';
              $('.properties-filter a.status').each(function () {
                if (!($(this).hasClass('featured-properties-filter'))) {
                  statuses += prop_prefix + $(this).data('status');
                  prop_prefix = ',';
                }
              });
            }
            var featured_only = false;
            if ($('.properties-filter a.featured-properties-filter.active').length) {
              featured_only = true;
            }
            var rewards_only = false;
            if ($('.section.properties .section-properties-content').data('rewards_only') === 'rewards') {
              rewards_only = true;
            }
            var except = '';
            if ($('.section.properties .properties-wrapper article.properties').length) {
              prefix = '';
              $('.section.properties .properties-wrapper article.properties').each(function () {
                except += prefix + $(this).data('property-id');
                prefix = ',';
              });
            }
            properties_from_bank = Sage.get_properties_from_bank(
              $('.section.properties .properties-bank'), // bank selector
              max_cards, // limit
              prop_type, // properties types
              location, // properties locations
              featured_only, // is featured only
              except, // except
              $('.section.properties .properties-wrapper .properties').length, // large not needed if length > 0
              statuses
            );
            // if there are properties in bank then load them
            if ($('.properties-bank').length) {
              // if bank has no enough properties to load into section
              if (properties_from_bank.length < max_cards) {
                var ids_not_in = ''; prefix = '';
                $('.section.properties .properties-wrapper .properties, .section.properties .properties-bank .properties').each(function() {
                  ids_not_in += prefix + $(this).data('property-id');
                  prefix = ',';
                });
                $('.section.properties .properties-bank').addClass('not-enough-properties');
                Sage.load_more_properties(
                  $('.section.properties .properties-bank'), // element selector
                  0, // offset
                  prop_type, // properties types
                  max_cards - properties_from_bank.length, // limit
                  location, // properties locations
                  featured_only, // is featured only
                  null,
                  statuses,
                  rewards_only
                );
              }
              else {
                var first;
                properties_from_bank.forEach(function(current_property) {
                  $('.section.properties .properties-wrapper')
                    .append(current_property)
                    .isotope('appended', current_property);
                  if ('undefined' === typeof(first)) {
                    first = current_property;
                  }
                });

                Sage.make_square_block($('.section.properties .properties-wrapper article .article-bg'), function() {
                  $('.section.properties .properties-wrapper').isotope('reloadItems').isotope('layout');
                });

                if (typeof(first) !== 'undefined') {
                  $('html, body').animate({
                    scrollTop: first.offset().top - ($('#wpadminbar').length ? $('#wpadminbar').height() : 0) - (first.height() / 2) - $('.banner').height()
                  }, 800);
                }
                if (!$('.properties-bank .properties').length && $('#load-more-properties').hasClass('no-more-news')) {
                  $('#load-more-properties').hide();
                }
                return false;
              }
            }

            Sage.make_square_block($('.section-properties .properties-wrapper article .article-bg'));
            $('.section.properties .properties-bank').each(function() {
              $(this).attr('style','');
            });
            if ($('.section.properties .properties-loader')) {
              $('.section.properties .properties-loader').hide();
            }
            $('.section.properties .properties-wrapper').isotope('reloadItems').isotope('layout');
          });
        }

        if(ms_ie) {
          var $parallaxBlock = $('.splash-image.parallax'),
              parallaxImage = $parallaxBlock.data('image-src');

          $parallaxBlock.css('background-image', 'url(' + parallaxImage + ')');
        } else {
          $(document).on('ready.px.parallax.data-api', function () {
            window.splash_parallax = $('.splash-image.parallax').parallax({
              imageSrc: $(this).data('image-src'),
              zIndex: 1,
              positionY: $(this).data('position-y')
            });
          });
          $(window).resize(function() {
            setTimeout(function () {
              $.fn.parallax.Constructor.refresh();
              $(window).trigger('scroll');
            }, 100);
          });
        }

        if ($('.we-chat-popup').length) {
          $('.we-chat-popup .close').click(function (e) {
            $('.we-chat-popup-link').each(function () {
              if ($(this).data('status') === 'fadeIn') {
                $('.we-chat-popup-link').data('status', 'fadeOut');
                $('.we-chat-popup').fadeOut();
              }
            });
          });
        }
        if ($('.we-chat-popup-link').length) {
          $('.we-chat-popup-link').click(function (e) {
            e.preventDefault();
            e.stopPropagation();
            if ($(this).data('status') === 'fadeOut') {
              $(this).data('status', 'fadeIn');
              $(this).siblings('.we-chat-popup').fadeIn();
            }
          });
        }

        if ($('.registration-widget form').length) {
          $('.registration-widget form').submit(function(e) {
            if (!$(this)[0].checkValidity()) {
              e.preventDefault();
              $(this).find('[required]').each(function () {
                if ($(this).attr('type') === "checkbox") {
                  if ($(this)[0].validationMessage) {
                    $(this).parent('label').css('color', '#f00');
                  }
                  else {
                    $(this).parent('label').css('color', '');
                  }
                }
                else {
                  if ($(this)[0].validationMessage) {
                    $(this).css('border-color', '#f00');
                    $(this).css('background-color', 'rgba(255,0,0,.05)');
                  }
                  else {
                    $(this).css('border-color', '');
                    $(this).css('background-color', '');
                  }
                }
              });
              $(this).find('.error-message').show();
              return false;
            }
            else {
              $(this).find('.error-message').hide();
            }
          });
        }

        if ($('.focus-form').length) {
          $('.consent').on('click', function() {
            if (!$('#is_consent').is(':checked')) {
              $('#is_consent').parent('.question').css('color', '#f00');
            }
          });
        }

        if ($('.section.register_for_updates').length) {
          var selected_form_selector;
          $('.choose-form').on('change', function() {
            selected_form_selector = ($('input[name="choose_form"]:checked').val());
            $('.section.register_for_updates').find('form').hide();
            if (selected_form_selector) {
              $('.section.register_for_updates').find('.' + selected_form_selector).show();
            }
            $(window).trigger('resize');
          });
          $('.section.register_for_updates button[type="submit"]').on('click', function() {
            if (!$(this).parents('form').find('.question input[type="checkbox"]').is(':checked')) {
              $(this).parents('form').find('.question').css('color', '#f00');
            }
          });
          $(window).load(function() {
            selected_form_selector = $('.section.register_for_updates input[name="choose_form"]:checked').val();
            if (selected_form_selector) {
              $('.section.register_for_updates').find('.' + selected_form_selector).show();
            }
          });
        }

        $('.hover-dropdown').on('hover', function() {
          var dropdown = $(this).find('.elementy-ul');
          var diff = $(window).width() - ($(this).parents('.dropdown').offset().left + dropdown.outerWidth());
          if (diff > 0) {
            $(this).find('.elementy-ul').css('right', -(diff > dropdown.width() ? dropdown.outerWidth() : diff));
          }
          else {
            $(this).find('.elementy-ul').css('right', 10);
          }
        });

        if($('.js-rewards-dropdown').length) {
          $('.rewards__accordion__group').each(function(){
            var $self = $(this),
                rowLength = $self.find('.rewards__row').length;

            if(rowLength == 0) {
              $self.remove();
            }
          });

          $('body').on('change', '.js-rewards-dropdown', function(){
            var $self = $(this),
                val = $self.find(":selected").val();

            $('.rewards__row, .js-rewards-accordion').hide();
            $('.rewards__row[data-cat="'+val+'"], .js-rewards-accordion[data-cat="'+val+'"]').show();

            $('.rewards__accordion__group').removeClass('is-active');
          });

          $('body').on('click', '.js-rewards-btn', function(){
            var $self = $(this),
                val = $self.data('cat');

            if($self.parents('.rewards__accordion__group').hasClass('is-active')) {
              $('.rewards__accordion__group').removeClass('is-active');
              return;
            }

            $('.rewards__accordion__group').removeClass('is-active');
            $self.parents('.rewards__accordion__group').addClass('is-active');
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        function centerMap(map) {
          var bounds = new google.maps.LatLngBounds();
          $.each( map.markers, function( i, marker ) {
            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
            bounds.extend( latlng );
          });
          if (map.markers.length === 1) {
            map.setCenter( bounds.getCenter() );
            map.setZoom(15);
          }
          else {
            map.fitBounds( bounds );
          }
        }

        function centerMapByActiveMarkers(map) {
          var activeMarkers = [];
          var bounds = new google.maps.LatLngBounds();
          $(window.map.activeTab + ' .single-location:not(.hidden) .property-link:not(.hidden)').each(function () {
            activeMarkers.push(window.map.markers[$(this).data('marker_number')]);
          });
          $.each(activeMarkers, function (i, marker) {
            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
            bounds.extend(latlng);
          });
          if (activeMarkers.length === 1) {
            map.setCenter(bounds.getCenter());
            map.setZoom(15);
          }
          else if (activeMarkers.length) {
            map.fitBounds(bounds);
          }
        }

        // Render Google Maps
        function render_map( $el ) {
          var $markers = $el.find('.marker');
          var args = {
            zoom        : 15,
            center      : new google.maps.LatLng(0, 0),
            mapTypeId   : google.maps.MapTypeId.ROADMAP,
            scrollwheel : false
          };
          var map = new google.maps.Map( $el[0], args);
          map.markers = [];
          $markers.each(function(){
            // Add markers
            var latlng = new google.maps.LatLng($(this).attr('data-lat'), $(this).attr('data-lng'));
            var marker = new google.maps.Marker({
              position : latlng,
              map : map
            });
            map.markers.push(marker);
            if ($(this).html()) {
              var infowindow = new google.maps.InfoWindow({
                content: $(this).html()
              });
              google.maps.event.addListener(marker, 'click', function() {
                infowindow.open( map, marker );
              });
            }
          });
          // Center Map
          centerMap(map);
        }

        if ($('.acf-map').length) {
          setTimeout(function(){
            $('.acf-map').each(function(){
              render_map($(this));
            });
          }, 1000);
        }


        // Render Properties Map
        function render_properties_map( $el ) {
          var $markers = $el.find('.marker');
          var args = {
            zoom        : 15,
            center      : new google.maps.LatLng(0, 0),
            mapTypeId   : google.maps.MapTypeId.ROADMAP,
            scrollwheel : false
          };
          window.map = new google.maps.Map( $el[0], args);
          window.map.markers = [];
          window.map.infowindows = [];
          $markers.each(function(){
            // Add markers
            var latlng = new google.maps.LatLng($(this).attr('data-lat'), $(this).attr('data-lng'));
            var marker = new google.maps.Marker({
              position : latlng,
              map : window.map,
              icon: '../../wp-content/themes/onni/dist/images/pin2.png'
            });
            window.map.markers.push(marker);
            var infowindow = new google.maps.InfoWindow({
              content: $(this).html()
            });
            google.maps.event.addListener(marker, 'click', function() {
              infowindow.open( window.map, marker );
            });
            window.map.infoWindows.push(infowindow);
          });
          var bounds = new google.maps.LatLngBounds();
          $.each( map.markers, function( i, marker ) {
            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
            bounds.extend( latlng );
          });
          if (window.map.markers.length === 1) {
            window.map.setCenter( bounds.getCenter() );
            window.map.setZoom(15);
          }
          else {
            window.map.fitBounds( bounds );
          }
          $('.section.properties_map .nav-item.active .nav-link').trigger('click');
        }

        function closeAllInfoWindows() {
          for (var i = 0; i < window.map.infowindows.length; i++) {
            window.map.infowindows[i].close();
          }
        }

        // Adds a marker to the map.
        function addMarker(lat, lng, map, address, property_name, link, icon) {
          // Add the marker at the clicked location, and add the next-available label
          // from the array of alphabetical characters.
          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            map: map,
            icon: icon
          });
          var infowindow = new google.maps.InfoWindow({
            content: "<div class=\"address\">" + address + "</div>" +
              "<div class=\"property-link\"><a href=\"" + link + "\">" +
                "<div class=\"property-name\"><strong>" + property_name + "</strong></div>" +
              "</a></div>"
          });
          google.maps.event.addListener(marker, 'click', function () {
            closeAllInfoWindows();
            infowindow.open(map, marker);
          });
          window.map.markers.push(marker);
          window.map.infowindows.push(infowindow);
        }

        /**
         * Stop displaying markers on the map
         */
        function removeMarkers() {
          // Don't show markers anymore
          for(var i = 0; i < window.map.markers.length; i++){
            window.map.markers[i].setMap(null);
          }
        }

        function getCurrentlyActiveStatuses(map_section) {
          if (map_section.find('.statuses .property_status.active').length) {
            var statuses_ids = [];
            map_section.find('.statuses .property_status.active').each(function () {
              statuses_ids.push($(this).data('status-id'));
            });
            return statuses_ids;
          }
          return [];
        }

        function getCurrentlyActiveLocations(map_section) {
          if (map_section.find('.top-locations .single-top-location.active').length) {
            var locations_ids = [];
            map_section.find('.top-locations .single-top-location.active').each(function () {
              locations_ids.push($(this).data('location-id'));
            });
            return locations_ids;
          }

          function sort_li(a, b){
            return ($(b).data('sort')) < ($(a).data('sort')) ? 1 : -1;
          }

          $('.js-location-sortable')
            .sort(sort_li)
            .appendTo('.top-locations');

          return [];
        }

        function isStatusMatches(property_element, currentlyActiveStatuses) {
          var is_matches = false;
          property_element.data('statuses').toString().split(',').forEach(function(currentStatus) {
            if ((currentlyActiveStatuses.indexOf(parseInt(currentStatus)) >= 0) || (currentlyActiveStatuses.length === 0)) {
              is_matches = true;
            }
          });
          return is_matches;
        }

        function isTopLocationMatches(property_element, currentlyActiveTopLocations) {
          var is_matches =
                  currentlyActiveTopLocations.indexOf(parseInt(property_element.closest('.single-location').data('parent-location'))) >= 0 || (currentlyActiveTopLocations.length === 0);
          return is_matches;
        }

        function refreshUpperLocations(map_section) {
          map_section.find('.properties-for-location').each(function () {
            if ($(this).find('.property-link:not(.hidden)').length) {
              $(this).parent().removeClass('hidden');
              $(this).parent().show();
            }
            else {
              $(this).parent().addClass('hidden');
              $(this).parent().hide();
            }
          });
          map_section.find(window.map.activeTab + ' .location-block.upper-location-name').each(function () {
            if ($(window.map.activeTab + ' .location-block.single-location[data-parent-location="' + $(this).data('current-location') + '"] .property-link:not(.hidden)').length) {
              $(this).removeClass('hidden');
              $(this).show();
            }
            else {
              $(this).addClass('hidden');
              $(this).hide();
            }
          });
          if (map_section.find(window.map.activeTab + ' .properties-for-location .property-link:not(.hidden)').length) {
            map_section.find('.no-properties').hide();
          }
          else {
            map_section.find('.no-properties').show();
          }
        }

        function updateTopLocationsFilter() {
          var locations_ids = [];
          $(window.map.activeTab + ' .upper-location-name ').each(function () {
            locations_ids.push($(this).data('current-location'));
          });

          $('.single-top-label').removeClass('is-active');

          $('.section.properties_map .top-locations .single-top-location').each(function () {
            if (locations_ids.indexOf($(this).data('location-id')) >= 0) {
              $(this).removeClass('empty');

              var activeCountry = $(this).data('location-parent');
              $('.single-top-label[data-location-id="'+activeCountry+'"]').addClass('is-active');
            }
            else {
              $(this).addClass('empty').removeClass('active');
            }
          });
        }

        /**
         * marker_number Number of marker in array window.map.markers
         */
        function focusMarker(marker_number) {
          closeAllInfoWindows();
          window.map.setCenter(window.map.markers[marker_number].getPosition());
          window.map.infowindows[marker_number].open(window.map, window.map.markers[marker_number]);
          window.map.setZoom(15);
        }

        function showMarkersFromTab(tabSelector) {
          removeMarkers();
          $(tabSelector + ' .property-link').each(function () {
            if (isStatusMatches($(this), getCurrentlyActiveStatuses($(tabSelector).parents('.section.properties_map'))) &&
              isTopLocationMatches($(this), getCurrentlyActiveLocations($(tabSelector).parents('.section.properties_map'))) &&
              $(this).data('lat') && $(this).data('lng')
            ) {
              // If there is a map marker assigned to this property
              if ($(this).data('marker_number')) {
                window.map.markers[$(this).data('marker_number')].setMap(window.map);
                window.map.markers[$(this).data('marker_number')].setPosition(
                  new google.maps.LatLng(
                    $(this).data('lat'),
                    $(this).data('lng')
                  )
                );
              }
              // If marker for property hasn't been set - create new
              else {
                $(this).attr('data-marker_number', window.map.markers.length);
                addMarker(
                  $(this).data('lat'),
                  $(this).data('lng'),
                  window.map,
                  $(this).data('address'),
                  $(this).data('name'),
                  $(this).data('link'),
                  $(this).data('marker')
                );
              }
              // Show property in list
              $(this).removeClass('hidden');
              $(this).show();
            }
            else {
              // Hide property in list
              $(this).addClass('hidden');
              $(this).hide();
            }
          });
          refreshUpperLocations($(tabSelector).parents('.section.properties_map'));
          if ($(window.map.activeTab + ' .properties-for-location .property-link:not(.hidden)').length === 1) {
            window.map.setCenter(window.map.markers[$(window.map.activeTab + ' .properties-for-location .property-link:not(.hidden)').data('marker_number')].getPosition());
            window.map.setZoom(15);
          }
          centerMapByActiveMarkers(window.map);
        }

        if ($('.properties-map').length) {
          setTimeout(function() {
            $('.properties-map').each(function(){
              render_properties_map($(this));
              setTimeout(function () {
                showMarkersFromTab(window.map.activeTab);
              }, 100);
            });

            $('.new-homes-map-view .property_status[data-status-id="44"]').click();
          }, 1000);

          if ($('.properties_map .tab-content .tab-pane').length) {
            $(window).on('load', function () {
              $('.properties_map .tab-content .tab-pane').isotope({
                transitionDuration: 0,
                layoutMode: 'masonry',
                itemSelector: '.location-block',
                masonry: {
                  columnWidth: '.location-block.single-location:not(.hidden)',
                  gutter: 0
                }
              });
            });
          }

          $('.section.properties_map a[data-toggle="tab"]').on('shown.bs.tab', function () {
            $('.properties_map .tab-content .tab-pane').isotope('layout');
          });

          $('.section.properties_map .property-link').click(function () {
            var marker_number = $(this).data('marker_number');
            if (typeof(marker_number) !== 'undefined') {
              focusMarker(marker_number);
            }
            var target_offset = $('.properties-map').offset();
            var target_top = target_offset.top - $('header.banner').height();
            $('html, body').animate({scrollTop: target_top}, 'slow');
          });

          // Tabs Switching
          $('.properties-list-container .nav-link').click(function () {
            window.map.activeTab = $(this).attr('href');
            updateTopLocationsFilter();
            showMarkersFromTab(window.map.activeTab);
            $('.properties_map .tab-content .tab-pane').isotope('layout');
          });

          // Property statuses
          $('.section.properties_map .statuses .property_status').click(function () {
            if ($(this).hasClass('active')) {
              return;
            }
            $(this).toggleClass('active');
            $(this).siblings().removeClass('active');
            var statuses_ids = [];
            $('.section.properties_map .statuses .property_status.active').each(function () {
              statuses_ids.push($(this).data('status-id'));
            });
            $('.section.properties_map .nav-item.active .nav-link').trigger('click');
          });

          // Property top location
          $('.section.properties_map .top-locations .single-top-location').click(function () {
            $(this).siblings().removeClass('active');
            $(this).toggleClass('active');
            $('.section.properties_map .nav-item.active .nav-link').trigger('click');
          });

          $('.section.properties_map .top-locations .single-top-location:first-child').click();
        }
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('header.banner .dropdown').on('show.bs.dropdown', function(e){
          if ($('.splash-image').length) {
            if ($(window).width() > 991) {
              $(this).find('.dropdown-menu').first().outerHeight($('.splash-image').outerHeight() - $('header.banner').outerHeight());
            }
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'single_property': {
      init: function() {
        // Hide Lasso section if there's resistration slide-down button
        if ($('.project-info-sidebar .register-link').length) {
          if ($('.section.registration-lasso').length) {
            $('.project-info-sidebar .register-link').click(function(e) {
              e.preventDefault();
              $('.section.registration-lasso').slideDown();
              $('html body').animate({
                scrollTop: $('.section.registration-lasso').offset().top
              }, 1000);
            });
          }
        }

        if ($('#registration_form').length) {
          $('#registration_form').submit(function(e) {
            // Safari - check if fields are valid
            if (!$(this)[0].checkValidity()) {
              e.preventDefault();
              $(this).find('[required]').each(function () {
                if ($(this).siblings('span').length) {
                  if ($(this)[0].validationMessage) {
                    $(this).siblings('span').css('color', '#f00');
                  }
                  else {
                    $(this).siblings('span').css('color', '');
                  }
                }
                else if($(this).parent().siblings('label').length) {
                  if ($(this)[0].validationMessage) {
                    $(this).parent().siblings('label').css('color', '#f00');
                  }
                  else {
                    $(this).parent().siblings('label').css('color', '');
                  }
                }
              });
              $(this).find('.error-message').show();
              return false;
            }
            else {
              $(this).find('.error-message').hide();
            }
            // Use Ajax to submit form data
            var modal_body = $(this).parents('.modal-body');
            var modal_header = modal_body.siblings('.modal-header');
            var loader = modal_body.find('.modal-loader-wrapper');
            loader.show = function() {
              $(this).css('display', 'flex');
            };
            $.ajax({
              url: $(this).attr('action'),
              type: 'POST',
              data: $(this).serialize(),
              beforeSend: function () {
                loader.show();
              },
              success: function(response) {
                if ($(response).find('.content').length) {
                  modal_body.html($(response).find('.content')[$(response).find('.content').length - 1].innerHTML);
                }
                loader.hide();
                if ($('.modal').css('position') === 'absolute') {
                  $('.modal').css('marginTop', $(window).scrollTop() + 'px');
                }
                else {
                  $('#registration_modal').animate({ scrollTop: 0 }, 'slow');
                }
              },
              error: function () {
                loader.hide();
                console.error('An error occured on form submittion.');
              },
            });
            return false;
          });
        }

        // iOS check
        if( navigator.userAgent.match(/iPhone|iPad|iPod/i) ) {
          $('.modal').on('show.bs.modal', function() {
            // Position modal absolute and bump it down to the scrollPosition
            $(this)
              .css({
                position: 'absolute',
                marginTop: $(window).scrollTop() + 'px',
                bottom: 'auto'
            });
            // Position backdrop absolute and make it span the entire page
            setTimeout( function() {
              $('.modal-backdrop').css({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: Math.max(
                  document.body.scrollHeight, document.documentElement.scrollHeight,
                  document.body.offsetHeight, document.documentElement.offsetHeight,
                  document.body.clientHeight, document.documentElement.clientHeight
                ) + 'px'
              });
            }, 0);
          });
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // Animation for Odometers
  function animateOdometers() {
    var odometer_elements = $('.odometer');
    odometer_elements.each(function() {
      var rect = $(this)[0].getBoundingClientRect();
      if (rect.top >= 0 && rect.left >= 0 && rect.bottom <= $(window).height() && rect.right <= $(window).width()) {
        setTimeout(function(){
          $('.odometer').each(function() {
            $(this).html($(this).attr('data-value'));
          });
        }, 100);
      }
    });
  }
  if ($('.odometer').length) {
    animateOdometers();
    $(window).scroll(function(event) {
      animateOdometers();
    });
  }

})(jQuery); // Fully reference jQuery after this point.

jQuery.fn.equalHeights = function () {
  var maxHeight = 0,
          self = jQuery(this);

  self.each(function () {
    var height = jQuery(this).innerHeight();

    if (height > maxHeight) {
      maxHeight = height;
    }
  });

  return self.css('height', maxHeight);
};
